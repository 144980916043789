import React from 'react';

const ColourButton = ({ colourObject, isSelected, handleOnClick }) => {
  const { ariaLabel, primaryColour, secondaryColour } = colourObject.fields;

  const content = (
    <div className="color-circle-container">
      <div className="circle-outline">
        <span
          className="circle-color"
          style={{
            backgroundColor: primaryColour.value,
          }}
        />
        {!!secondaryColour && (
          <span
            className="circle-color"
            style={{
              backgroundColor: secondaryColour.value,
            }}
          />
        )}
      </div>
      <span className="vh">{ariaLabel ? ariaLabel.value : ''}</span>
    </div>
  );

  return isSelected ? (
    <li>
      <button
        type="button"
        role="radio"
        aria-checked
        className="colour-option is-selected"
      >
        {content}
      </button>
    </li>
  ) : (
    <li>
      <button
        onClick={() => handleOnClick(colourObject)}
        type="button"
        role="radio"
        className="colour-option"
        aria-checked={false}
      >
        {content}
      </button>
    </li>
  );
};

export default ColourButton;
