import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Text, RichText, Image } from '@sitecore-jss/sitecore-jss-react';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';
import ColourButton from './Components/ColourButton';

class ProductBrief extends React.Component {
  constructor(props) {
    super(props);
    if (!!this.props.fields.products) {
      const firstProduct = this.props.fields.products[0];
      this.state = {
        activeProduct: firstProduct,
        activeColour: firstProduct.fields.colourOptions[0],
      };
    } else {
      this.state = {
        activeProduct: null,
        activeColour: null,
      };
    }
  }

  setProductVariant = (productVariant) => {
    this.setState({
      activeProduct: productVariant,
      activeColour: productVariant.fields.colourOptions[0],
    });
  };

  setColourVariant = (colourVariant) => {
    this.setState({
      activeColour: colourVariant,
    });
  };

  render() {
    const {
      title,
      description,
      productColourOptionTitle,
      productVariantTitle,
      products,
      hasFade,
    } = this.props.fields;

    const { activeProduct, activeColour } = this.state;

    const content = (
      <div className="product-color-switcher">
        <div className="l-padding">
          <div className="content-container">
            <div>
              <Text field={title} tag="h2" />
              <RichText field={description} tag="p" />
            </div>
            <div className="product-tabs">
              {!!products && products.length > 1 && (
                <div className="product-info">
                  <Text field={productVariantTitle} tag="p" />
                  <div className="product-options">
                    {products.map((product) => {
                      const isSelected = product.id === activeProduct.id;
                      return isSelected ? (
                        <button
                          type="button"
                          role="radio"
                          aria-checked
                          key={product.id}
                          className="product-option is-selected"
                        >
                          <Text field={product.fields.name} />
                        </button>
                      ) : (
                        <button
                          onClick={() => this.setProductVariant(product)}
                          type="button"
                          role="radio"
                          key={product.id}
                          aria-checked="false"
                          className="product-option"
                        >
                          <Text field={product.fields.name} />
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
              <div className="product-config">
                <Text field={productColourOptionTitle} tag="p" />
                <ul>
                  {!!activeProduct &&
                    activeProduct.fields.colourOptions.map((colour) => (
                      <ColourButton
                        key={colour.id}
                        colourObject={colour}
                        isSelected={colour.id === activeColour.id}
                        handleOnClick={() => this.setColourVariant(colour)}
                      />
                    ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="image-container">
            {activeProduct.fields.colourOptions.map((colour) => (
              <Image
                field={colour.fields.productImage}
                key={colour.id}
                className={activeColour === colour ? 'is-active' : ''}
              />
            ))}
          </div>
        </div>
      </div>
    );

    return hasFade && hasFade.value ? (
      <ScrollAnimation
        animateIn="toFadeInUp"
        animateOnce
        delay={100}
        duration={0.5}
      >
        {content}
      </ScrollAnimation>
    ) : (
      content
    );
  }
}

ProductBrief.propTypes = PropTypes.SitecoreItem.inject({
  ...PropTypes.ContentBlock,
  products: PropTypes.Multilist.inject({
    name: PropTypes.SingleLineText,
    colourOptions: PropTypes.Multilist.inject({
      ariaLabel: PropTypes.SingleLineText,
      primaryColour: PropTypes.SingleLineText,
      secondaryColour: PropTypes.SingleLineText,
    }),
  }),
  productColourOptionTitle: PropTypes.SingleLineText,
  productVariantTitle: PropTypes.SingleLineText,
  hasFade: PropTypes.Checkbox,
});

ProductBrief.defaultProps = DefaultProps.SitecoreItem.inject({
  ...DefaultProps.ContentBlock,
  products: DefaultProps.Multilist,
  productColourOptionTitle: DefaultProps.SingleLineText,
  productVariantTitle: DefaultProps.SingleLineText,
  hasFade: DefaultProps.Checkbox,
});

export default ProductBrief;
